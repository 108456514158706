<template>
  <b-card
    :title="[!systemId ? 'เพิ่ม' : 'แก้ไข'] + 'ข้อมูลระบบ'"
    class="form-system"
  >
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(fncSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col cols="12">
            <b-row class="justify-content-md-center">
              <b-col
                :cols="$store.state.app.windowWidth >= 1360 ? 8 : 12"
              >
                <b-form-group
                  label-cols-md="2"
                  label-cols-sm="3"
                  label-align-md="right"
                >
                  <template v-slot:label>
                    ชื่อระบบ <span class="text-danger required-font-style">*</span>
                  </template>
                  <validation-provider
                    #default="validationContext"
                    name="systemName"
                    rules="required"
                  >
                    <b-form-input
                      id="systemName"
                      v-model="systemName"
                      trim
                      placeholder="ชื่อระบบ"
                      autocomplete="off"
                      :state="getValidationState(validationContext)"
                    />
                    <small class="text-danger">
                      {{ validationContext.errors[0] ? 'กรุณากรอกชื่อระบบ' : '' }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row :class="`justify-content-md-center ${isLocalServer ? 'delete-margin' : ''}`">
              <b-col
                :cols="$store.state.app.windowWidth >= 1360 ? 8 : 12"
              >
                <b-form-group
                  label-cols-md="2"
                  label-cols-sm="3"
                  label-align-md="right"
                >
                  <template v-slot:label>
                    นามแฝง <span class="text-danger required-font-style">*</span>
                  </template>
                  <validation-provider
                    #default="validationContext"
                    name="systemAlias"
                    rules="required"
                  >
                    <b-form-input
                      id="systemAlias"
                      v-model="systemAlias"
                      trim
                      placeholder="นามแฝง"
                      autocomplete="off"
                      :state="getValidationState(validationContext)"
                    />
                    <small class="text-danger">
                      {{ validationContext.errors[0] ? 'กรุณากรอกนามแฝง' : '' }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row :class="`justify-content-md-center ${isLocalServer ? 'delete-margin' : ''}`">
              <b-col
                :cols="$store.state.app.windowWidth >= 1360 ? 8 : 12"
              >
                <b-form-group
                  label-cols-md="2"
                  label-cols-sm="3"
                  label-align-md="right"
                >
                  <template v-slot:label>
                    ระบบย่อย
                  </template>
                  <b-button
                    size="sm"
                    variant="outline-primary"
                    @click="fncAddSystemParent()"
                  >
                    เพิ่มระบบย่อย
                  </b-button>
                  <b-table-simple
                    small
                    caption-top
                    responsive
                    class="position-relative mt-1"
                  >
                    <b-thead head-variant="light">
                      <b-tr>
                        <b-th
                          v-for="(item, index) in tableTitle"
                          :key="index"
                          :style="item.style ? item.style : ''"
                        >
                          {{ item.title }}
                        </b-th>
                      </b-tr>
                    </b-thead>
                    <draggable
                      v-model="dataSystemParent"
                      tag="tbody"
                    >
                      <template v-if="dataSystemParent.length">
                        <b-tr
                          v-for="(item, index) in dataSystemParent"
                          :key="index"
                        >
                          <b-td>
                            {{ Number(index) + 1 }}
                          </b-td>
                          <b-td style="padding-left: 0rem;">
                            <b-form-input
                              v-model="item.system_parent_name"
                              placeholder="ชื่อระบบย่อย"
                            />
                          </b-td>
                          <b-td style="padding-left: 0rem;">
                            <b-form-input
                              v-model="item.system_parent_alias"
                              placeholder="นามแฝงระบบย่อย"
                            />
                          </b-td>
                          <b-td>
                            <feather-icon
                              v-b-popover.hover.top="'ลบข้อมูล'"
                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              icon="TrashIcon"
                              class="cursor-pointer"
                              size="16"
                              @click="dataSystemParent.splice(index, 1)"
                            />
                          </b-td>
                        </b-tr>
                      </template>
                    </draggable>
                  </b-table-simple>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row :class="`justify-content-md-center ${isLocalServer ? 'delete-margin' : ''}`">
              <b-col
                :cols="$store.state.app.windowWidth >= 1360 ? 8 : 12"
              >
                <b-form-group
                  label-cols-md="2"
                  label-cols-sm="3"
                  label-align-md="right"
                >
                  <!-- Form Actions -->
                  <div class="d-flex mt-2">
                    <b-overlay
                      :show="overlaySubmit"
                      rounded
                      opacity="0.6"
                      spinner-small
                      spinner-variant="primary"
                      class="d-inline-block"
                    >
                      <b-button
                        variant="primary"
                        class="mr-2"
                        type="submit"
                      >
                        <feather-icon icon="SaveIcon" />
                        บันทึกข้อมูล
                      </b-button>
                    </b-overlay>
                    <b-button
                      type="button"
                      variant="outline-secondary"
                      :to="{ path: '/system' }"
                    >
                      ยกเลิก
                    </b-button>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  VBPopover, BCard, BForm, BRow, BCol, BFormGroup, BFormInput, BOverlay, BButton, BTableSimple, BThead, BTr, BTh, BTd,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import draggable from 'vuedraggable'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

import useSystemForm from './useSystemForm'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BOverlay,
    BButton,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,

    draggable,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    Ripple,
    'b-popover': VBPopover,
  },

  setup() {
    const {
      // variable index
      systemId,

      // variable table
      tableTitle,

      // variable input
      systemName,
      systemAlias,

      // variable data
      dataSystemParent,

      // loading animation
      overlaySubmit,

      // function
      fncSubmit,
      fncAddSystemParent,

      // helper
      isLocalServer,
    } = useSystemForm()

    // vee validate
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      // variable index
      systemId,

      // variable table
      tableTitle,

      // variable input
      systemName,
      systemAlias,

      // variable data
      dataSystemParent,

      // loading animation
      overlaySubmit,

      // function
      fncSubmit,
      fncAddSystemParent,

      // helper
      isLocalServer,

      // vee validate
      refFormObserver,
      getValidationState,
      resetForm,
      required,
    }
  },
}
</script>

<style>
.form-system .form-row > legend
{
  margin-top: 10px;
}

.form-system .delete-margin {
  margin-top: -40px;
}

.table thead th, .table tfoot th {
    vertical-align: middle;
    text-transform: uppercase;
    font-size: 0.94rem;
    font-weight: bold;;
    letter-spacing: 0.5px;
    height: 40px;
}

.table tbody td {
    vertical-align: middle;
    letter-spacing: 0.5px;
    height: 40px;
}
</style>
