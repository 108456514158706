import { ref, computed, onMounted } from '@vue/composition-api'
import router from '@/router'
import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useSystemForm() {
  // Notification
  const toast = useToast()

  // vairable index
  const systemId = router.currentRoute.params.id

  // variable table
  const tableTitle = [
    { title: 'ลำดับ', style: 'min-width: 80px; width:80px;' },
    { title: 'ชื่อระบบย่อย', style: 'min-width: 300px;' },
    { title: 'นามแฝงย่อย', style: 'min-width: 280px; width:280px;' },
    { title: 'เครื่องมือ', style: 'min-width: 80px; width:80px;' },
  ]

  // variable input
  const systemName = ref(null)
  const systemAlias = ref(null)

  // variable data
  const dataSystemParent = ref([])

  // loading animation
  const overlaySubmit = ref(false)

  const fncSubmit = () => {
    overlaySubmit.value = true

    const payload = {
      system_id: systemId,
      system_name: systemName.value,
      system_alias: systemAlias.value,
      system_parent: dataSystemParent.value,
    }

    if (!systemId) delete payload.system_id

    if (!systemId) {
      axios
        .post('/System', { requestData: payload })
        .then(() => {
          router.push('/system')

          toast({
            component: ToastificationContent,
            props: {
              title: 'แจ้งเตือน',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'บันทึกข้อมูลระบบสำเร็จ',
            },
          })

          overlaySubmit.value = false
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'แจ้งเตือน',
              text: `พบข้อผิดพลาดในการบันทึกข้อมูล [${error}]`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })

          overlaySubmit.value = false
        })
    } else {
      axios
        .put('/System', { requestData: payload })
        .then(() => {
          router.push('/system')

          toast({
            component: ToastificationContent,
            props: {
              title: 'แจ้งเตือน',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'แก้ไขข้อมูลระบบสำเร็จ',
            },
          })

          overlaySubmit.value = false
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'แจ้งเตือน',
              text: `พบข้อผิดพลาดในการแก้ไขข้อมูล [${error}]`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })

          overlaySubmit.value = false
        })
    }
  }

  const fncAddSystemParent = () => {
    dataSystemParent.value.push({
      system_parent_id: null,
      system_parent_name: null,
      system_parent_alias: null,
    })
  }

  // helper
  const isLocalServer = computed(() => {
    const getServerName = window.location.host.includes(':') ? window.location.host.split(':')[0] : window.location.host
    return getServerName === 'localhost'
  })

  const fncFetchDataEdit = () => {
    axios
      .get(`/System/${systemId}`)
      .then(response => {
        const { responseData } = response.data

        if (response.data.status) {
          systemName.value = responseData.system_name
          systemAlias.value = responseData.system_alias
          dataSystemParent.value = responseData.system_detail
        } else {
          toast({
            component: ToastificationContent,
            props: {
              title: 'แจ้งเตือน',
              text: `พบข้อผิดพลาด [${responseData.detail}]`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'แจ้งเตือน',
            text: `พบข้อผิดพลาด [${error}]`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  onMounted(() => {
    if (systemId) fncFetchDataEdit()
  })

  return {
    // variable index
    systemId,

    // variable table
    tableTitle,

    // variable input
    systemName,
    systemAlias,

    // variable data
    dataSystemParent,

    // loading animation
    overlaySubmit,

    // function
    fncSubmit,
    fncAddSystemParent,

    // helper
    isLocalServer,
  }
}
